<template>
    <div class="pt-3 mb-3">
        <div role="tablist">
            <!-- item all -->
            <b-card class="accordion-card" no-body>
                <b-card-body style="padding: 0;">
                    <b-list-group>
                        <b-list-group-item
                            :variant="sub_a_variant"
                            class="d-flex justify-content-between align-items-center"
                            style="padding: 0.50rem 1.25rem"
                            to="/market/null"
                        >
                            {{$t('public.product.all')}}
                            <b-badge pill variant="primary">{{allCount}}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-body>
            </b-card>
            <!-- Items -->
            <div v-for="item in getItems">
                <b-card class="accordion-card" no-body>
                    <b-card-header class="accordion-header" role="tab">
                        <b-button v-b-toggle="'accordion-'+ item.id" :variant="topVariant"
                                  block
                                  class="d-flex justify-content-between align-items-center"
                        >
                            <h5 class="mb-0 text-left">{{item.title}}</h5>
                            <b-badge pill variant="success">{{item.count}}</b-badge>
                        </b-button>
                    </b-card-header>
                    <!-- subItems_a -->
                    <b-collapse
                        :id="'accordion-' + item.id"
                        accordion="accordion"
                        role="tabpanel"
                    >
                        <div v-for="subItem_a in item.subItems_a">
                            <b-card-body style="padding: 0;">
                                <b-list-group>
                                    <b-list-group-item
                                        :to="'/market/' + subItem_a.type_id"
                                        :variant="sub_a_variant"
                                        class="d-flex justify-content-between align-items-center"
                                        style="padding: 0.50rem 1.25rem"
                                    >
                                        {{subItem_a.title}}
                                        <b-badge pill variant="primary">{{subItem_a.count}}</b-badge>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card-body>
                        </div>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccordionProduct",
    components: {},
    props: {
        topVariant: {
            type: String,
            default() {
                return 'secondary'
            }
        },
        sub_a_variant: {
            type: String,
            default() {
                return 'primary'
            }
        }
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                uri_public: `api/v1/_public/accordion_product`,
            },
            records: null,
            allCount: null,
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            this.axios.get(this.uri.uri_public)
                .then((response) => {
                    this.$set(this, 'records', response.data.records);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getGroups() {
            let records = this.records;
            // <--  -->
            let array = [];
            let record, group, id, name, count;
            let localeTemp;
            let exists = false;
            let allCount = 0;
            Object.keys(records).find((key) => {
                record = records[key];
                id = record.product_group_id;
                count = record.products_count;
                allCount += count;
                group = record.product_group;
                // <-- check if group already exists -->
                exists = false;
                array.find((n, index) => {
                    if (n.id === id) {
                        n.count += count;
                        exists = true;
                    }
                });
                // <-- push -->
                if (!exists) {
                    name = group.meta.name;
                    localeTemp = this.__getJsonLocaleValue(group.locale, 'name')
                    name = localeTemp === null ? name : localeTemp;
                    array.push(
                        {
                            id: id,
                            title: name,
                            count: count,
                            order: group.order,
                        }
                    );
                }
            });
            // <-- sort -->
            // array = array.sort((a, b) => b.order - a.order);
            // <--  -->
            this.allCount = allCount;
            return array;
        },
        getItems() {
            if (this.records === null) {
                return [];
            }
            // <--  -->
            let items = [];
            // <-- top items -->
            let topItems = this.getGroups;
            let record;
            topItems.forEach((topItem, topIndex) => {
                // <-- push to items -->
                items.push(
                    {
                        id: topItem.id,
                        title: topItem.title,
                        count: topItem.count,
                        // variant: 'secondary',
                    }
                )
                // <-- sub items a -->
                let subItems_a = [];
                let title;
                let localeTemp;
                Object.keys(this.records).find((key) => {
                    record = this.records[key];
                    if (topItem.id === record.product_group_id) {
                        title = record.meta.name;
                        localeTemp = this.__getJsonLocaleValue(record.locale, 'name')
                        title = localeTemp === null ? title : localeTemp;
                        subItems_a.push(
                            {
                                title: title,
                                count: record.products_count,
                                type_id: record.id,
                                // variant: 'primary',
                            }
                        );
                        if (subItems_a.length > 0) {
                            items[topIndex].subItems_a = subItems_a;
                        }
                    }
                });
            });
            // <--  -->
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
